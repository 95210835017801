import {
    createStyles,
    Image,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
  } from '@mantine/core';
  import { IconCheck } from '@tabler/icons';
import { RegisterModal } from './RegisterModal';
  
  const useStyles = createStyles((theme) => ({
    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing.xl * 4,
      paddingBottom: theme.spacing.xl * 4,
    },
  
    content: {
      maxWidth: 480,
      marginRight: theme.spacing.xl * 3,
  
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        marginRight: 0,
      },
    },
  
    title: {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: 44,
      lineHeight: 1.2,
      fontWeight: 900,
  
      [theme.fn.smallerThan('xs')]: {
        fontSize: 28,
      },
    },
  
    control: {
      [theme.fn.smallerThan('xs')]: {
        flex: 1,
      },
    },
  
    image: {
      flex: 1,
  
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },
  
    highlight: {
      position: 'relative',
      backgroundColor: theme.fn.variant({ variant: 'light', color: 'violet' }).background,
      borderRadius: theme.radius.sm,
      padding: '4px 12px',
    },
  }));
  
  export function FeatureHeroHeader() {
    const { classes } = useStyles();
    return (
      <div>
        <Container>
          <div className={classes.inner}>
            <div className={classes.content}>
              <Title className={classes.title}>
                Build <span className={classes.highlight}>Scalable</span> <br /> Game Servers
              </Title>
              <Text color="dimmed" mt="md">
                Build fully scalable game servers using our transatlantic network of servers.
              </Text>
  
              <List
                mt={30}
                spacing="sm"
                size="sm"
                icon={
                  <ThemeIcon size={20} radius="xl" color='violet'>
                    <IconCheck size={12} stroke={1.5} />
                  </ThemeIcon>
                }
              >
                <List.Item>
                  High performance game servers using our server shapes: 2GB RAM, 4GB RAM, 8GB RAM & 16GB RAM
                </List.Item>
                <List.Item>
                  Fuss free server tuning. Scale or descale your infrastructure with the click of a button
                </List.Item>
                <List.Item>
                  Create scalable game server networks. Link servers together to form a larger network of maps in your game
                </List.Item>
              </List>
  
              <Group mt={30}>
                <RegisterModal buttonsize={"md"}/>
              </Group>
            </div>
            <Image src={'/images/trendingup.png'} className={classes.image} />
          </div>
        </Container>
      </div>
    );
  }