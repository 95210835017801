import { PasswordInput, Input, Button, Center, Space, Stack, Checkbox, Group } from "@mantine/core";

export function RegisterForm() {
    return (
        <><Stack><Input.Wrapper
            id="input-username"
            withAsterisk
            label="Create Username"
            description="Please enter a username"
            error=""
            size="md"
        >
            <Input id="input-username" placeholder="Enter username" disabled />
        </Input.Wrapper><Input.Wrapper
            id="input-email"
            withAsterisk
            label="Email Address"
            description=""
            error=""
            size="md"
        >
            <Input id="input-email" placeholder="Your email" disabled />
        </Input.Wrapper><PasswordInput
                placeholder="Password"
                label="Create Password"
                description="Password must include at least one letter, number and special character"
                error=""
                size="md"
                disabled
                withAsterisk /><PasswordInput
                placeholder="Confirm Password"
                label="Confirm Password"
                description="Password must match the password above"
                error=""
                size="md"
                disabled
                withAsterisk />
        <Center>
            <Group position="center">
                <Checkbox
                    label="I am at least 16 years old and/or have parental consent"
                    color="violet"
                />
                <Checkbox
                    label="I have read and agree to the terms of service"
                    color="violet"
                />
            </Group>
        </Center>
        </Stack>
        <Space h="md" />
        <Center>
            <Button color="violet" size="md" disabled>
                Register
            </Button>
        </Center></>
    );
}