import { useState } from 'react';

import { Header, Tabs, Image, Group, Button, Burger, Alert, Divider, Menu, Text } from '@mantine/core';
import { GetDeviceStatus } from '../functions/mobile';
import { Link } from 'react-router-dom';
import { MinecloudLogo } from '../assets/MinecloudLogo';
import { IconHome2, IconClipboardList, IconReceipt2, IconLogin } from '@tabler/icons';

function ProcessTabs(highlight: string) {
  return (
    <><Tabs color="violet" variant="pills" defaultValue={highlight}>
      <Tabs.List position="center">
        <Link style={{textDecoration: 'none'}} to="/"><Tabs.Tab value="home">Home</Tabs.Tab></Link>
        <Link style={{textDecoration: 'none'}} to="/features"><Tabs.Tab value="features">Features</Tabs.Tab></Link>
        <Link style={{textDecoration: 'none'}} to="/pricing"><Tabs.Tab value="pricing">Pricing</Tabs.Tab></Link>
      </Tabs.List>
    </Tabs><Button color="violet" disabled>
        Login
      </Button></>
  );
}

function HeaderTabs() {
  const [opened, setOpened] = useState(false);
  const title = opened ? 'Close navigation' : 'Open navigation';
  if (GetDeviceStatus()) {
    return (
      <Menu shadow="md" width={"100vh"}>
        <Menu.Target>
          <Button variant="subtle" color="violet">
            Menu
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Navigation Menu</Menu.Label>
          <Link to={'/'}><Menu.Item icon={<IconHome2 size={14} />}>Home</Menu.Item></Link>
          <Link to={'/features'}><Menu.Item icon={<IconClipboardList size={14} />}>Features</Menu.Item></Link>
          <Link to={'/pricing'}><Menu.Item icon={<IconReceipt2 size={14} />}>Pricing</Menu.Item></Link>
          <Menu.Divider />
          <Menu.Label>Authentication</Menu.Label>
          <Menu.Item icon={<IconLogin size={14} />}>Login</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  }
  else {
    if (window.location.pathname == '/' || window.location.pathname == '/home') {
      return ProcessTabs('home');
    }
    else if (window.location.pathname == '/features') {
      return ProcessTabs('features');
    }
    else if (window.location.pathname == '/pricing') {
      return ProcessTabs('pricing');
    }
    else {
      return ProcessTabs('');
    }
  }
}

function CalltoAction() {
  return (
    <Alert icon={<Image width={24} height={24} fit="contain" src="/images/icons/tabler-icon-alert-circle.svg"></Image>} title="Under Construction!" color="yellow" radius="xs">
      This service is currently being developed! Game servers are currently unavailable, please refer to our blog for updates.
    </Alert>
  );
}

export function PrimaryHeader() {
  return (
    <><CalltoAction /><Header height={60} p="xs">{<Group position="apart"><><MinecloudLogo width={60} height={50} /><HeaderTabs /></></Group>}</Header><Divider size="md" /></>
  );
}