import { Title } from '@mantine/core';
import { Background } from '../essential/Background';
import { FooterLinks } from '../essential/Footer';
import { PrimaryHeader } from '../essential/PrimaryHeader';
import { RegisterModal } from '../essential/RegisterModal';

function HomeBGText () {
    return (
        <Title
            order={1}
            align="center"
            variant="gradient"
            gradient={{ from: 'violet', to: 'white', deg: 90 }}
            weight={1000}
            style={{ fontFamily: 'Greycliff CF, sans-serif' }}
        >
            Affordable. Scalable. Game Servers.
        </Title>
    )
}

export function Home() {
    return (
        <><PrimaryHeader />
        <Background heads={<HomeBGText />} desc={undefined} inter={<RegisterModal buttonsize={'lg'} />} />
        </>
    );
}