import {
    ThemeIcon,
    Text,
    Image,
    Title,
    Container,
    SimpleGrid,
    useMantineTheme,
    createStyles,
    Divider,
    Group,
    Center,
    Space,
  } from '@mantine/core';
import { IconServerBolt, IconNetwork, IconServerOff, IconClick, IconPaperclip, IconChartDots3, TablerIcon, IconBug, IconGift, IconLayoutDashboard } from '@tabler/icons';
import { FeatureHeroHeader } from '../essential/FeatureHeroHeader';
import { FooterLinks } from '../essential/Footer';
import { PrimaryHeader } from '../essential/PrimaryHeader';

const HARDWARE = [
    {
        icon: IconServerBolt,
        title: 'Extreme Performance',
        description:
        'Using powerful Intel® Xeon® Gold and AMD EPYC™ 2nd Gen processors, NVMe SSDs and gigabit networking to deliver you with high preformance gameplay.',
    },
    {
        icon: IconNetwork,
        title: 'Low Latency',
        description:
        'Deploy anywhere within our transatlantic network, with servers in North America (USA) and Europe (Germany & Finland) to deliver the lowest possible latency for your players.',
    },
    {
        icon: IconServerOff,
        title: 'Inactive Mode',
        description:
        'Configure your server to go into an inactive state when no players are connected to save money. (select games only)',
    }
  ];

const EXTRA = [
    {
        icon: IconChartDots3,
        title: 'Instant Setup',
        description:
        'Your server is provisioned and activated automatically, so you can get to your game as fast as possible.',
    },
    {
        icon: IconClick,
        title: 'One-click Modpacks',
        description:
        'Create, install and play modpacks with a click of a button!',
    },
    {
        icon: IconPaperclip,
        title: 'Plugin/Mod Cache',
        description:
        'Store your plugins, mods and modpacks in our cache to reduce your inactive storage bill.',
    },
    {
        icon: IconGift,
        title: 'Sub-domain',
        description:
        'Get a free and customisable subdomain with your server to make it easier for all of your players to access your server.',
    },
    {
        icon: IconLayoutDashboard,
        title: 'Dashboard',
        description:
        'Configure and edit your server with ease using our custom dashboard.',
    },
    {
        icon: IconBug,
        title: 'Errors',
        description:
        'Find a wealth of guides to help you fix common errors which occur on game servers.',
    }
  ];
  
  interface FeatureProps {
    icon: TablerIcon;
    title: React.ReactNode;
    description: React.ReactNode;
  }

  export function Feature({ icon: Icon, title, description }: FeatureProps) {
    const theme = useMantineTheme();
    return (
      <div>
        <ThemeIcon variant="light" color="violet" size={40} radius={40}>
          <Icon size={20} stroke={1.5} />
        </ThemeIcon>
        <Text style={{ marginTop: theme.spacing.sm, marginBottom: 7 }}>{title}</Text>
        <Text size="sm" color="dimmed" style={{ lineHeight: 1.6 }}>
          {description}
        </Text>
      </div>
    );
  }
  
  const useStyles = createStyles((theme) => ({
    wrapper: {
      paddingTop: theme.spacing.xl * 2,
      paddingBottom: theme.spacing.xl * 2,
    },
  
    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 900,
      marginBottom: theme.spacing.md,
      textAlign: 'center',
  
      [theme.fn.smallerThan('sm')]: {
        fontSize: 28,
        textAlign: 'left',
      },
    },
  
    description: {
      textAlign: 'center',
  
      [theme.fn.smallerThan('sm')]: {
        textAlign: 'left',
      },
    },
  }));
  
  interface FeaturesGridProps {
    title: React.ReactNode;
    description: React.ReactNode;
    data?: FeatureProps[];
  }
  
  function HardwareFeaturesGrid({ title, description, data = HARDWARE }: FeaturesGridProps) {
    const features = data.map((feature, index) => <Feature {...feature} key={index} />);
    return (
        <FeaturesGrid features={features} title={title} description={description} />
    )
  }

  function ExtraFeaturesGrid({ title, description, data = EXTRA }: FeaturesGridProps) {
    const features = data.map((feature, index) => <Feature {...feature} key={index} />);
    return (
        <FeaturesGrid features={features} title={title} description={description} />
    )
  }

  interface WrapFeatureProp {
    features: React.ReactNode;
    title: React.ReactNode;
    description: React.ReactNode;
  }

  function FeaturesGrid({features, title, description}: WrapFeatureProp) {
    const { classes, theme } = useStyles();
    return (
        <Container className={classes.wrapper}>
          <Title className={classes.title}>{title}</Title>
    
          <Container size={560} p={0}>
            <Text size="sm" className={classes.description}>
              {description}
            </Text>
          </Container>
    
          <SimpleGrid
            mt={30}
            cols={3}
            spacing={theme.spacing.xl * 2}
            breakpoints={[
              { maxWidth: 980, cols: 2, spacing: 'xl' },
              { maxWidth: 755, cols: 1, spacing: 'xl' },
            ]}
          >
            {features}
          </SimpleGrid>
        </Container>
    );
  }

  export function FeaturePage() {
    return (
        <><PrimaryHeader />
        <FeatureHeroHeader />
        <Divider size="md" />
        <HardwareFeaturesGrid title={'Big on hardware. Low on price.'} description={'Make use of our flexible server shapes which are billed on an hourly basis so you only pay for what you use!'} />
        <Divider size="md" />
        <ExtraFeaturesGrid title={'And much more...'} description={'We are constantly making improvements to our service adding new features by taking feedback from our customers to enhance your gameplay.'} />
        <FooterLinks data={[]} /></>
    );
  }