import { createStyles, Title, Text, Button, Container, Group } from '@mantine/core';
import { Link } from 'react-router-dom';
import { PrimaryHeader } from "../essential/PrimaryHeader";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[0],

    [theme.fn.smallerThan('sm')]: {
      fontSize: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

export function NotFound() {
  const { classes } = useStyles();

  return (
    <><PrimaryHeader /><Container className={classes.root}>
          <div className={classes.label}>404</div>
          <Title className={classes.title}>This is a hidden page!</Title>
          <Text color="dimmed" size="lg" align="center" className={classes.description}>
              Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has
              been moved to another URL.
          </Text>
          <Group position="center">
            <Link style={{textDecoration: 'none'}} to="/"><Button variant="outline" color="violet" size="md">
                Return to home page
            </Button></Link>
          </Group>
      </Container></>
  );
}