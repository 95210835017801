import { Image } from '@mantine/core';

interface MinecloudLogoProps {
    width: number;
    height: number;
  }

export function MinecloudLogo({width, height}: MinecloudLogoProps) {
    return (
        <Image
            width={width}
            height={height}
            fit="contain"
            src="/images/minecloud-logo.png"
        />
    );
}