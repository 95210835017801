import { Modal, Button, Alert, Divider, Image, MantineSize } from "@mantine/core";
import { useState } from "react";
import { RegisterForm } from "./RegisterForm";

function ModalNote() {
    return (
        <><Alert icon={<Image width={24} height={24} fit="contain" src="/images/icons/tabler-icon-alert-circle.svg"></Image>} title="Feature Disabled!" color="yellow" radius="xs">
            Thank you for taking an interest in our services. We are working hard to develop our system as best and as fast as possible and will inform you when registrations are open.
        </Alert><Divider my="sm" variant="dashed" /></>
    );
}

interface RegisterModalProps {
    buttonsize: MantineSize;
}

export function RegisterModal ({buttonsize}: RegisterModalProps) {
    const [opened, setOpened] = useState(false);
    return (
        <><Modal
            opened={opened}
            size="auto"
            onClose={() => setOpened(false)}
            title="Create an account with Minecloud."
        >
            {<><ModalNote /><RegisterForm /></>}
        </Modal><Button color="violet" size={buttonsize} onClick={() => setOpened(true)}>
                Get Started
            </Button></>
    );
}